<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>
      <v-icon v-else>
        mdi-menu
      </v-icon>
    </v-btn>

    <v-toolbar-title class="hidden-sm-and-down font-weight-regular" v-text="$route.name"/>

    <v-spacer/>
    <v-spacer/>

    <div class="mx-3"/>

    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-badge :color="notifications.length?'red':'green'" overlap bordered>
            <template v-slot:badge>
              <span>{{notifications.length}}</span>
            </template>
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <app-bar-item
            v-for="(notification, notificationId) in notifications"
            :key="notificationId">
            <v-list-item-icon>
              <v-icon :color="notification.notificationType==='order'?'error':'blue'">
                {{notification.notificationType==='order'?'mdi-progress-alert':'mdi-database'}}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="notification.notificationText"
                               v-on:click="confirmNotification(notification.notificationId)"/>
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <v-menu bottom left offset-y origin="top right" transition="expand-transition">
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-translate</v-icon>
        </v-btn>
      </template>

      <v-list nav>
        <div>
          <app-bar-item v-for="(l, id) in languages" :key="id" style="padding: 0;">
            <v-btn small text v-text="l.lang" v-on:click="changeLanguage(l.val)"/>
            <!--            <v-list-item-title v-text="l.lang" v-on:click="changeLanguage(l.val)"/>-->
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <v-btn class="ml-2" min-width="0" text to="/settings">
      <v-icon>mdi-cogs</v-icon>
    </v-btn>

    <v-btn class="ml-2" min-width="0" text to="/pages/user">
      <v-icon>mdi-account</v-icon>
    </v-btn>

    <v-btn class="ml-2" min-width="0" text @click="handleLogout">
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
  import {VHover, VListItem} from 'vuetify/lib'
  import {AXIOS} from "../../../../../http-commons";
  import i18n from "../../../../i18n";
  import {mapState, mapMutations} from 'vuex'
  import {useOrders} from "../../../../stores/ordersStore";
  import {useUser} from "../../../../stores/userStore";
  import {usePayers} from "../../../../stores/payersStore";
  import {useAgencies} from "../../../../stores/agenciesStore";
  import EventBus from "../../../../common/EventBus";
  import {useFiles} from "../../../../stores/filesStore";
  import {useManagers} from "../../../../stores/managersStore";
  import {useTasks} from "../../../../stores/tasksStore";
  import {mapWritableState} from 'pinia'

  const agencyStore = useAgencies()
  const ordersStore = useOrders()
  const userStore = useUser()
  const payersStore = usePayers()
  const filesStore = useFiles()
  const managersStore = useManagers()
  const tasksStore = useTasks()

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render(h) {
          return h(VHover, {
            scopedSlots: {
              default: ({hover}) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-3': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      // languages: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','),
      languages: [
        {id: 2, val: 'uk', lang: 'UA'},
        {id: 1, val: 'en', lang: 'EN'},
        {id: 3, val: 'de', lang: 'DE'},
        {id: 4, val: 'fr', lang: 'FR'},
        {id: 5, val: 'es', lang: 'ES'},
        {id: 6, val: 'cn', lang: 'CN'}
      ]
    }),
    computed: {
      ...mapState(['drawer']),
      ...mapWritableState(useOrders, ['notifications']),
      ...mapWritableState(useUser, ['userSettings'])
    },
    methods: {
      confirmNotification(notificationId) {
        let params = new URLSearchParams();
        params.append('notificationId', notificationId);
        AXIOS.put('/api/confirmNotification', params)
          .then(response => {
              ordersStore.deleteNotification(response.data)
            },
            error => {
              if (error.response && error.response.status === 403) {
                EventBus.dispatch("logout");
              }
            })
      },
      mounted() {
        EventBus.on("logout", () => {
          this.logOut();
        });
      },
      beforeDestroy() {
        EventBus.remove("logout");
      },
      handleLogout() {
        agencyStore.$reset()
        payersStore.$reset()
        userStore.$reset()
        ordersStore.$reset()
        filesStore.$reset()
        managersStore.$reset()
        tasksStore.$reset()
        this.$router.push('/');
        this.$store.dispatch('auth/logout');
      },
      changeLanguage(lang) {
        if (this.$i18n.locale !== lang) {
          this.$i18n.locale = lang;
        }
      },
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
    },
    async created() {
      let locale = await userStore.getUserSettingsAsync();
      this.$i18n.locale = locale.data.uiLanguage;
      console.log("locale: ",this.$i18n.locale)
    }
  }
</script>
